import React from 'react';
import { motion } from 'framer-motion';

//Assets
import hiw_icon_1 from '../../../../../asset/resource/hiw-icon-1.png';
import hiw_icon_2 from '../../../../../asset/resource/hiw-icon-2.png';
import hiw_icon_3 from '../../../../../asset/resource/hiw-icon-3.png';
import hiw_icon_4 from '../../../../../asset/resource/hiw-icon-4.png';
import hiw_icon_5 from '../../../../../asset/resource/hiw-icon-5.png';

import { useCheckMobile } from '../../../../hooks';

import './HowItWorks.scss';

export const HowItWorks = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="how-it-works" className="how-it-works">
      <div className="how-it-works__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.3 }}
        >
          <span className="sub-title">The Core Technology</span>
        </motion.div>

        <div className="how-it-works__blocks">
          <div className="how-it-works__block one">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1, scale: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8 }}
            >
              <article>
                <figure className="how-it-works__image">
                  <img width="100%" src={hiw_icon_1} alt="" />
                </figure>
                <div className="how-it-works__article-content">
                  <h3>NVIDIA Isaac Sim™</h3>
                  <p>
                    An extensible robotics simulator that gives you a faster,
                    better way to design, test, and train AI-based robots. It's
                    powered by Omniverse™ to deliver scalable, photorealistic,
                    and physically accurate virtual environments for building
                    high-fidelity simulations.
                  </p>
                </div>
              </article>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8, delay: 0.5 }}
            >
              <article>
                <figure className="how-it-works__image">
                  <img width="100%" src={hiw_icon_2} alt="" />
                </figure>
                <div className="how-it-works__article-content">
                  <h3>NVIDIA Omniverse™ </h3>
                  <p>
                    A platform that provides developers with the building
                    blocks–developer tools, APIs and microservices–to bridge
                    data silos and create physically accurate world-scale
                    simulations, powered by OpenUSD and NVIDIA RTX.
                  </p>
                </div>
              </article>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8 }}
            >
              <article>
                <figure className="how-it-works__image">
                  <img width="100%" src={hiw_icon_3} alt="" />
                </figure>
                <div className="how-it-works__article-content">
                  <h3>Virtual Reality with Robotic Collaboration</h3>
                  <p>
                    SoftServe's vertical farming simulation uses VR technology,
                    allowing users to interact with robots in virtual
                    environments. Navigation within the simulation is done
                    through VR headsets, controllers, and tracking systems.
                  </p>
                </div>
              </article>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8, delay: 0.5 }}
            >
              <article>
                <figure className="how-it-works__image">
                  <img width="100%" src={hiw_icon_4} alt="" />
                </figure>
                <div className="how-it-works__article-content">
                  <h3>Real-Time Data Analysis</h3>
                  <p>
                    Data analytics and visualization tools continuously monitor
                    and optimize farming operations within the VR environment.
                    Simultaneously, a user-friendly VR control panel displays
                    crucial data for informed decisions that impact crop growth
                    and farm efficiency.
                  </p>
                </div>
              </article>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8 }}
            >
              <article>
                <figure className="how-it-works__image">
                  <img width="100%" src={hiw_icon_5} alt="" />
                </figure>
                <div className="how-it-works__article-content">
                  <h3>Intuitive User Guidance</h3>
                  <p>
                    Voiceover prompts guide you through tasks, offering
                    instructions and feedback based on user actions.
                  </p>
                </div>
              </article>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
