import React from 'react';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

//Assets
import robotic_development_image from '../../../../../asset/resource/configuration_planning_image.png';
import human_robot_image from '../../../../../asset/resource/human_robot_image.png';
import training_operators_image from '../../../../../asset/resource/robotic_development_image.png';
import configuration_planning_image from '../../../../../asset/resource/training_operators_image.png';

import { useCheckMobile } from '../../../../hooks';

import './UseCases.scss';

const UseCases = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="use-cases" className="use-cases">
      <div className="use-cases__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <div className="sub-title-wrapper">
            <span className="sub-title">Use Cases</span>
          </div>
        </motion.div>
        <div className="use-cases__slider-wrapper">
          <Carousel
            showArrows
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance={40}
          >
            <div className="use-cases__slide-wrapper">
              <div className="use-cases__slide">
                <article>
                  <h3>Custom Robotic Development</h3>
                  <p>
                    <ul>
                      <li>
                        Prototype and fine-tune AI-based robotic systems with
                        simulation before investing in physical prototypes.
                      </li>
                      <li>
                        Develop and test robots tailored for specific tasks,
                        optimizing performance and functionality.
                      </li>
                    </ul>
                  </p>
                </article>
                <figure className="use-cases__image">
                  <img
                    width="100%"
                    src={robotic_development_image}
                    className="support-image"
                    alt="support image"
                  />
                </figure>
              </div>
            </div>
            <div className="use-cases__slide-wrapper">
              <div className="use-cases__slide">
                <article>
                  <h3>Training Simulations for Human-Robot Collaboration:</h3>
                  <p>
                    <ul>
                      <li>
                        Train human operators in virtual collaborative workflows
                        with AI-driven robots.
                      </li>
                      <li>
                        Integrate robotic assistants into operational tasks for
                        greater productivity.
                      </li>
                    </ul>
                  </p>
                </article>
                <figure className="use-cases__image">
                  <img
                    width="100%"
                    src={human_robot_image}
                    className="onboarding-image"
                    alt="onboarding image"
                  />
                </figure>
              </div>
            </div>
            <div className="use-cases__slide-wrapper">
              <div className="use-cases__slide">
                <article>
                  <h3>Training for Operators:</h3>
                  <p>
                    <ul>
                      <li>
                        Use simulations as an essential training tool for robot
                        operators.{' '}
                      </li>
                      <li>
                        Provide a practical and interactive platform for
                        operators to familiarize themselves with AI-driven
                        systems.
                      </li>
                    </ul>
                  </p>
                </article>
                <figure className="use-cases__image">
                  <img
                    width="100%"
                    src={training_operators_image}
                    className="planing-image"
                    alt="planing image"
                  />
                </figure>
              </div>
            </div>
            <div className="use-cases__slide-wrapper">
              <div className="use-cases__slide">
                <article>
                  <h3>Layout and Configuration Planning:</h3>
                  <p>
                    <ul>
                      <li>
                        Plan and optimize layouts such as crop configurations in
                        vertical farms.{' '}
                      </li>
                      <li>
                        Test different configurations to maximize sunlight
                        exposure, improve airflow, and improve overall
                        operations.
                      </li>
                      <li>
                        Implement changes in the physical setting more
                        effectively after thorough simulation testing.
                      </li>
                    </ul>
                  </p>
                </article>
                <figure className="use-cases__image">
                  <img
                    width="100%"
                    src={configuration_planning_image}
                    className="education-image"
                    alt="education image"
                  />
                </figure>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
