import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import primaryVideo from '../../../../../asset/resource/robot_1.mp4';
import safariVideo from '../../../../../asset/resource/robot_safari_1.mov';
import heroGif from '../../../../../asset/resource/robot.gif';
import { useCheckMobile } from '../../../../hooks';

import './Title.scss';

export const Title = () => {
  const isMobile = useCheckMobile({ width: 640 });
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);
  }, [isSafariBrowser]);
  return (
    <>
      <section className={cn('main-title')}>
        <div className="main-title__wrapper">
          {isMobile && (
            <video
              className="main-title__hero-video main-title__hero-video_mobile"
              loop
              muted
              playsInline
              autoPlay
            >
              {isSafariBrowser ? (
                <source src={safariVideo} type="video/mp4" />
              ) : (
                <source src={primaryVideo} type="video/webm" />
              )}
            </video>
          )}
          <div>
            <div>
              <h1>
                SIMULATION-FIRST <br /> CUSTOM <br /> ROBOTICS 
              </h1>
            </div>
            <div>
              <strong>
                Design, build, run, and improve custom robots with simulation.
                <br />
                Drastically cut costs and physical iterations.
              </strong>
            </div>
          </div>
        </div>
        {!isMobile && (
          <video
            className="main-title__hero-video"
            loop
            muted
            playsInline
            autoPlay
          >
            {isSafariBrowser ? (
              <source src={safariVideo} type="video/mp4" />
            ) : (
              <source src={primaryVideo} type="video/webm" />
            )}
          </video>
        )}
      </section>
      <section className="main-title__extended-description">
        <div className="wrapper main-title__extended-description-content">
          <p>
            Discover the benefits of a simulation-first approach. It offers
            safer testing, rapid iteration, advanced debugging, and
            optimization. This approach is transforming custom robotic
            development across industries. Simulation-first custom robotics
            improves operator training, human-robot collaboration, and
            industrial planning.
          </p>
          <div className="main-title__extended-description_highlighted">
            <span>
              Experience the human-robot collaboration for yourself in a
            </span>{' '}
            <p>physically accurate simulated vertical farm demo environment.</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Title;
