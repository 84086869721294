import React from 'react';
import { motion } from 'framer-motion';

//Assets
import how_to_use_bg_image from '../../../../../asset/resource/how-to-use-bg-image.png';
import icon from '../../../../../asset/resource/form-image-bg-small.png';
import { useCheckMobile } from '../../../../hooks';

import './HowToUse.scss';

export const HowToUse = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="how-to-use" className="how-to-use">
      <div className="how-to-use__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.5 }}
        >
          <span className="sub-title">The Benefits</span>
        </motion.div>

        <div className="how-to-use__items-block">
          <div className="how-to-use__item">
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.2 }}
              >
                <div className="how-to-use__item-icon">
                  <img src={icon} alt="" />
                  <h3>Cost-Efficient</h3>
                </div>
                <p>
                  <ul>
                    <li>
                      Test designs in a virtual environment, minimizing the need
                      for expensive physical prototypes.
                    </li>
                    <li>
                      Adopt a simulation-first approach to optimize and
                      fine-tune before implementation.
                    </li>
                    <li>Significantly reduce custom robotics design costs.</li>
                  </ul>
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.4 }}
              >
                <div className="how-to-use__item-icon">
                  <img src={icon} alt="" />
                  <h3>Safer Testing</h3>
                </div>
                <p>
                  <ul>
                    <li>Experiment without the risk of damage or injury.</li>
                    <li>
                      Improve worker safety through virtual testing of robot
                      performance.
                    </li>
                  </ul>
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.6 }}
              >
                <div className="how-to-use__item-icon">
                  <img src={icon} alt="" />
                  <h3>Rapid Iteration:</h3>
                </div>
                <p>
                  <ul>
                    <li>
                      Quickly iterate on designs and see immediate impacts.
                    </li>
                    <li>Minimize errors and maximize productivity.</li>
                    <li>
                      Test a wide range of designs under various conditions,
                      enhancing scalability.
                    </li>
                  </ul>
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="how-to-use__items-block last">
          <div className="how-to-use__item">
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.2 }}
              >
                <h3>Debugging</h3>
                <p>
                  <ul>
                    <li>
                      Effectively identify and isolate issues in a simulated
                      environment.
                    </li>
                    <li>
                      Control variables, reproduce issues, and track down causes
                      more efficiently.
                    </li>
                  </ul>
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.8, delay: 0.4 }}
              >
                <h3>Optimization</h3>
                <p>
                  <ul>
                    <li>
                      Optimize robotic behavior in a simulated environment for
                      enhanced safety measures.
                    </li>
                    <li>
                      Identify and address potential design flaws and
                      inefficiencies.
                    </li>
                  </ul>
                </p>
              </motion.div>
              <div className="how-to-use__item-image">
                <img src={how_to_use_bg_image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToUse;
